.App {
  text-align: center;
}

.appbar {
  background-color: #00ffff;
}

.App-logo {
  height:20vh;
   width:16vw;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.image-gallery-thumbnail-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
