.resizable-container {
    position: relative;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
    background-color: #333;
  }

  .modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal.open {
    display: flex;
    align-items: center;
    justify-content: center;
  }